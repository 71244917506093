<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">รวมยอดตลอดปี</h5>
                <ul class="ChartMap">
                  <li><span></span> กำไร {{bigLineChart.tolat.profit}} THB</li>
                  <li><span></span> ฝาก {{bigLineChart.tolat.deposit}} THB</li>
                  <li><span></span> ถอน {{bigLineChart.tolat.withdraw}} THB</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories" :key="option" class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="initBigChart(index)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === index">
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
      <!-- ////// month ///// -->
      <div class="col-lg-12" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">เดือน {{monthLineChartLineChart.month}}</h5>
            <ul class="ChartMap">
              <li><span></span> กำไร {{monthLineChartLineChart.tolat.profit}} THB</li>
              <li><span></span> ฝาก {{monthLineChartLineChart.tolat.deposit}} THB</li>
              <li><span></span> ถอน {{monthLineChartLineChart.tolat.withdraw}} THB</li>
            </ul>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="purple-line-chart"
                        :chart-data="monthLineChartLineChart.chartData"
                        :gradient-colors="monthLineChartLineChart.gradientColors"
                        :gradient-stops="monthLineChartLineChart.gradientStops"
                        :extra-options="monthLineChartLineChart.extraOptions">
            </line-chart>
          </div>
          <div class="chart-total mt-3"></div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">สมาชิก-สัปดาห์</h5>
            <h3 class="card-title"><i class="tim-icons icon-single-02 text-primary "></i> {{purpleLineChart.sumWeek | forMatNymber}} ID</h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="purple-line-chart"
                        :chart-data="purpleLineChart.chartData"
                        :gradient-colors="purpleLineChart.gradientColors"
                        :gradient-stops="purpleLineChart.gradientStops"
                        :extra-options="purpleLineChart.extraOptions">
            </line-chart>
          </div>
          <div class="chart-total mt-3">
            <div class="row">
              <div class="col-6 text-center"><h4>สมาชิกใหม่วันนี้</h4><h5>{{purpleLineChart.sumToday | forMatNymber}} ID</h5></div>
              <div class="col-6 text-center"><h4>สมาชิกทั้งหมด</h4><h5>{{purpleLineChart.dataAll | forMatNymber}} ID</h5></div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">ยอดฝาก-สัปดาห์</h5>
            <h3 class="card-title"><i class="tim-icons icon-coins text-info "></i> {{blueBarChart.sumWeek | forMatMony}} THB</h3>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                       chart-id="blue-bar-chart"
                       :chart-data="blueBarChart.chartData"
                       :gradient-stops="blueBarChart.gradientStops"
                       :extra-options="blueBarChart.extraOptions">
            </bar-chart>
          </div>
          <div class="chart-total mt-3">
            <div class="row">
              <div class="col-6 text-center"><h4>ยอดฝากวันนี้</h4><h5>{{blueBarChart.sumToday | forMatMony}} THB</h5></div>
              <div class="col-6 text-center"><h4>ยอดฝากทั้งหมด</h4><h5>{{blueBarChart.dataAll | forMatMony}} THB</h5></div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">ยอดถอน-สัปดาห์</h5>
            <h3 class="card-title"><i class="tim-icons icon-money-coins text-success "></i> {{greenLineChart.sumWeek | forMatMony}} THB</h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        chart-id="green-line-chart"
                        :chart-data="greenLineChart.chartData"
                        :gradient-stops="greenLineChart.gradientStops"
                        :extra-options="greenLineChart.extraOptions">
            </line-chart>
          </div>
          <div class="chart-total mt-3">
            <div class="row">
              <div class="col-6 text-center"><h4>ยอดถอนวันนี้</h4><h5>{{greenLineChart.sumToday | forMatMony}} THB</h5></div>
              <div class="col-6 text-center"><h4>ยอดถอนทั้งหมด</h4><h5>{{greenLineChart.dataAll | forMatMony}} THB</h5></div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-lg-6 col-md-12" v-if="viewLog">
        <card type="tasks" :header-classes="{'text-right': isRTL}">
          <template slot="header">
            <h6 class="title d-inline">{{$t('dashboard.tasks', {count: taskList.length})}}</h6>
            <p class="card-category d-inline">{{$t('dashboard.today')}}</p>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Settings menu"
                           :class="{'float-left': isRTL}">
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
              <a class="dropdown-item" href="#" @click="">เรียกรายการใหม่</a>
            </base-dropdown>
          </template>
          <div class="table-full-width table-responsive">
            <task-list :taskList="taskList" @infolog-modals="infolog"></task-list>
          </div>
        </card>
      </div> -->
      <div class="col-lg-12 col-md-12" v-if="viewDas">
        <card class="card" :header-classes="{'text-right': isRTL}">
          <h4 slot="header" class="card-title">
            <span>สรุปทั้งหมด</span>
            <span class="pull-right"> คงเหลือ Agent : {{Agentcredit | forMatMony}} Credit</span>
          </h4>
          <div class="table-responsive">
            <div >
              <card  style="margin-bottom: 10px;">
                <div class="row">
                  <div class="col-md-4 text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา เริ่มต้น" v-model="forms.start" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="dd-MM-yyyy" v-model="forms.start"> </datetime>
                  </div>
                  <div class="col-md-5  text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา สิ้นสุด" v-model="forms.end" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="dd-MM-yyyy" v-model="forms.end"></datetime>
                  </div> 
                  <div class="col-md-3">
                    <base-button style="margin-right: 5px;" round icon type="primary"  @click="findReport()">
                        <i class="tim-icons icon-zoom-split"></i>
                    </base-button>
                    <base-button style="margin-right: 5px;" round icon type="primary"  @click="clearList()">
                        <i class="tim-icons icon-refresh-02"></i>
                    </base-button>
                    <base-button type="info" style="width: 60%" @click="exReport()">
                      <i class="tim-icons icon-cloud-download-93"></i> รวมรายการฝาก
                    </base-button>
                  </div>
                </div>
              </card>
            </div>
            <!-- <user-table :data="table.data" :columns="table.columns" ></user-table> -->
            <base-table-user :data="table1.data" :columns="table1.columns" :columnsTh="table1.columnsTh" @openModal="infolog" thead-classes="text-primary"></base-table-user>
          </div>
        </card>
      </div>
    </div>
    <!-- //////// -->

    <modal :show.sync="modals.open"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-xl"
           
           >
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0 w-200"
              >
            <template>
              <div style="font-size: 18px; color: black;"><strong>สรุปรายการประจำวันที่ {{ modals.data }}</strong></div>
              <div class="row">
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="people-fill" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">สมาชิกใหม่ทั้งหมด</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.newMemberCount }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="people-fill" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">สมาชิกใหม่เติมเงิน</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.memberCountWithDeposit }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="people-fill" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">สมาชิกกลับมาฝาก</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.oldMemberDepositCount }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">ยอดฝากแรกเข้า</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.totalNewAmount ? formatCurrency(summaryAll.totalNewAmount) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">ยอดฝากทั่วไป</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.totalOldAmount ? formatCurrency(summaryAll.totalOldAmount) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="cash-stack" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">ยอดฝากรวม</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.totalAmountDeposit ? formatCurrency(summaryAll.totalAmountDeposit) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                  <div class="col-md-3">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                        <div class="icon-container">
                            <b-icon icon="cash-stack" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">ยอดถอนรวม</p>
                            <h4 class="mb-0 font-weight-bold">{{ summaryAll.totalWithdrawAmount ? formatCurrency(summaryAll.totalWithdrawAmount) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                </div>
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <span class="badge badge-success" style="font-size: 18px;">รายการฝาก</span>
                    <base-table-daily-details :data="tableD.data" :columns="tableD.columns" :columnth="tableD.columnsTH" :sort="tableD.sort" @SortTable="SortTable" :title="tableD.title" />
                  </div>
                  <div class="col-md-12">
                    <span class="badge badge-danger" style="font-size: 18px;">รายการถอน</span>
                    <base-table-daily-details :data="tableW.data" :columns="tableW.columns" :columnth="tableW.columnsTH" :sort="tableW.sort" @SortTable="SortTableW" :title="tableW.title" />
                  </div>
                </div>
                
              </div>
            </template>
            <button autofocus type="button" class="btn btn-secondary" @click="modals.open = false">Close</button>
        </card>
    </modal>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import TaskList from './Dashboard/TaskList';
  import UserTable from './Dashboard/UserTable';
  import { BaseTableUser, BaseTableDailyDetails } from '@/components/';
  import { Datetime } from 'vue-datetime'
  import JsonViewer from 'vue-json-viewer'
  import 'vue-json-viewer/style.css'
  import {
    Modal
  } from "@/components";
  import 'vue-datetime/dist/vue-datetime.css'
  ////
  import io from 'socket.io-client';
  import axios from 'axios';
  import utils from '@/utils';
  import config from '@/config.js';
  const tableColumns1 = ["_id","newMemberCount","memberCountWithDeposit", "totalNewAmount","oldMemberDepositCount", "totalOldAmount", "totalAmountDeposit", "totalWithdrawAmount"];
  const tableColumnsTh1 = ["วันที่", "สมาชิกใหม่ทั้งหมด","สมาชิกใหม่เติมเงิน", "ยอดฝากแรกเข้า" ,"สมาชิกกลับมาฝาก","ยอดฝากทั่วไป", "ยอดฝากรวม", "ยอดถอนรวม"];
  const tableData1 = [];
  const tableDColumns = ["account","name","amount", "type"];
  const tableDColumnsTH = ["ลำดับ","ยูเซอร์", "ชื่อ","จำนวนเงินที่ฝาก", "สมาชิก"];
  const tableDData = [];
  const tableWColumns = ["account","name","amount"];
  const tableWColumnsTH = ["ลำดับ","ยูเซอร์", "ชื่อ","จำนวนเงินที่ถอน"];
  const tableWData = [];
  ////

  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      UserTable,
      Datetime,
      JsonViewer,
      Modal,
      BaseTableUser,
      BaseTableDailyDetails
    },
    data() {
      return {
        viewLog : utils.permission('LOGS_VIEW'),
        viewDas : utils.permission('DASHBOARD_VIEW'),
        socket: io.connect(config.ws),
        Agentcredit:0,
        modals:{
          open:false,
          data:{}
        },
        currentDate: {},
        taskList: [],
        forms:{},
        table1: {
          title: "รายละเอียดเพื่อน",
          columns: [...tableColumns1],
          columnsTh: [...tableColumnsTh1],
          data: [...tableData1]
        },
        summaryAll: {
        },
        tableD: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                columns: [...tableDColumns],
                columnsTH: [...tableDColumnsTH],
                data: [...tableDData]
        },
        tableW: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                columns: [...tableWColumns],
                columnsTH: [...tableWColumnsTH],
                data: [...tableWData]
        },
        table:{
          "expoet":[],
          "columns": [ "name", "sum" ,"objct"],
          "data": [
            {
              "id": 0,
              "key":"members",
              "name": "สมาชิกใหม่",
              "sum": 0,
              "objct":"ไอดี"
            },
            {
              "id": 1,
              "key":"membersTopup",
              "name": "สมาชิกใหม่เติมเงิน",
              "sum": 0,
              "objct":"ไอดี"
            }, 
            {
              "id": 1,
              "key":"returntopup",
              "name": "สมาชิกกลับมาฝากอีก",
              "sum": 0,
              "objct":"ไอดี"
            },
            {
              "id": 1,
              "key":"onetopup",
              "name": "สมัครฝากครั้งเดียว",
              "sum": 0,
              "objct":"ไอดี"
            },
            {
              "id": 1,
              "key":"sumtopup",
              "name": "รวมรายการฝาก",
              "sum": 0,
              "objct":"รายการ"
            },
            {
              "id": 1,
              "key":"summembertopup",
              "name": "จากสมาชิกที่เติม",
              "sum": 0,
              "objct":"ไอดี"
            },
            // {
            //   "id": 2,
            //   "key":"creditfree",
            //   "name": "เคดิตฟรี",
            //   "sum": 0,
            //   "objct":"เคดิต"
            // },
            // {
            //   "id": 3,
            //   "key":"Memloss",
            //   "name": "จำนวนผู้ขอยอดเสีย",
            //   "sum": 0,
            //   "objct":"ไอดี"
            // },
            // {
            //   "id": 3,
            //   "key":"Loss",
            //   "name": "คืนยอดเสีย",
            //   "sum": 0,
            //   "objct":"เคดิต"
            // },
            {
              "id": 4,
              "key":"Deposit",
              "name": "ยอดฝาก",
              "sum": 0,
              "objct":"บาท"
            },
            {
              "id": 5,
              "key":"Withdraw",
              "name": "ยอดถอน",
              "sum": 0,
              "objct":"บาท"
            },
            {
              "id": 6,
              "key":"lucre",
              "name": "กำไร",
              "sum": 0,
              "objct":"บาท"
            }
          ]
        },
        // bigLineChart: {
        //   tolat: {
        //     profit:0,
        //     deposit:0,
        //     withdraw:0
        //   },
        //   allData: {
        //     profit:[],
        //     deposit:[],
        //     withdraw:[]
        //   },
        //   activeIndex: 0,
        //   chartData: {
        //     datasets: [{}],
        //     labels: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        //   },
        //   extraOptions: chartConfigs.purpleChartOptions,
        //   gradientColors: config.colors.primaryGradient,
        //   gradientStops: [1, 0.4, 0],
        //   categories: []
        // },
        bigLineChart: {
          tolat: {
            profit:0,
            deposit:0,
            withdraw:0
          },
          extraOptions: {
            maintainAspectRatio: false,
              legend: {
                display: false
              },
            responsive: true,
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: "nearest",
              intersect: 0,
              position: "nearest"
            },
            scales: {
              yAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  suggestedMin: 50,
                  suggestedMax: 110,
                  padding: 20,
                  fontColor: "#ff8a76"
                }
              }],
              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(220,53,69,0.1)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#ff8a76"
                }
              }]
            }
          },
          chartData: {
            labels: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
            datasets: [{
              label: "กำไร",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },{
              label: "ถอน",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },{
              label: "ฝาก",
              fill: true,
              borderColor: '#1d8cf8',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1d8cf8',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1d8cf8',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            }]
          }
        },
        monthLineChartLineChart: {
          tolat: {
            profit:0,
            deposit:0,
            withdraw:0
          },
          month: utils.nameMonth(),
          extraOptions: {
            maintainAspectRatio: false,
              legend: {
                display: false
              },
            responsive: true,
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: "nearest",
              intersect: 0,
              position: "nearest"
            },
            scales: {
              yAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  suggestedMin: 50,
                  suggestedMax: 110,
                  padding: 20,
                  fontColor: "#ff8a76"
                }
              }],
              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(220,53,69,0.1)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#ff8a76"
                }
              }]
            }
          },
          chartData: {
            labels: utils.getDaysInMonth(),
            datasets: [{
              label: "กำไร",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: utils.getDaysInMonthNum(),
            },{
              label: "ถอน",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: utils.getDaysInMonthNum(),
            },{
              label: "ฝาก",
              fill: true,
              borderColor: '#1d8cf8',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1d8cf8',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1d8cf8',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: utils.getDaysInMonthNum(),
            }]
          }
        },
        purpleLineChart: {
          sumToday:0,
          sumWeek:0,
          dataAll:0,
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
            datasets: [{
              label: "สรุปยอด",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0],
        },
        greenLineChart: {
          sumToday:0,
          sumWeek:0,
          dataAll:0,
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
            datasets: [{
              label: "สรุปยอด",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            }]
          },
          gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
          gradientStops: [1, 0.4, 0],
        },
        blueBarChart: {
          sumToday:0,
          sumWeek:0,
          dataAll:0,
          extraOptions: chartConfigs.barChartOptions,
          chartData: {
            labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
            datasets: [{
              label: "สรุปยอด",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
        }
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        //return this.$t('dashboard.chartCategories');
        let getYear = parseInt(new Date().getFullYear());
        return [ `${getYear}`  ]
      }
    },
    methods: {
      infolog(item){
        this.modals.open = true;
        this.modals.data = item;
        axios(config.api('post', '/report/findSumDetails/',{start:`${item}`,end:`${item}`})).then((tableReq)=>{
          this.summaryAll = tableReq.data.ResultData.data;
          this.tableD.data = tableReq.data.ResultData.data.members;
          this.tableW.data = tableReq.data.ResultData.data.withdrawMembers;
          
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      },
      SortTable(column){ 
            // console.log('column',column);
            let sortType = (this.tableD.sort.col == column)?(this.tableD.sort.even == 'desc')?'aec':'desc':this.tableD.sort.even;
            let ObjK = {};
            ObjK[column] = this.tableD.data;
            this.tableD.sort.col = column;
            this.tableD.sort.even = sortType; 
            this.tableD.data = utils.Sort[sortType](ObjK)
      },
      SortTableW(column){ 
            // console.log('column',column);
            let sortType = (this.tableW.sort.col == column)?(this.tableW.sort.even == 'desc')?'aec':'desc':this.tableW.sort.even;
            let ObjK = {};
            ObjK[column] = this.tableW.data;
            this.tableWsort.col = column;
            this.tableW.sort.even = sortType; 
            this.tableW.data = utils.Sort[sortType](ObjK)
        },
      findReport(){
        //console.log('findReport ->',this.forms);
        axios(config.api('post', '/report/findReportSunAll/',this.forms)).then((tableReq)=>{
        //console.log('findSumAll ->',tableReq.data.ResultData.data);
            this.table1.data = tableReq.data.ResultData.data;
            this.Agentcredit = tableReq.data.ResultData.AgentCredit.credit;
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
      },
      formatCurrency(value) {
            return new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            }).format(parseFloat(value));
      },
      exReport(){ 
        const blob = new Blob([JSON.stringify(this.table1.data, null, 2)], {
          type: 'application/json',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Report.json`;
        a.click();
        URL.revokeObjectURL(url); 
      },
      clearList(){
        axios(config.api('post', '/report/findReportSunAll/',this.currentDate)).then((tableReq)=>{
            this.table1.data = tableReq.data.ResultData.data;
            this.Agentcredit = tableReq.data.ResultData.AgentCredit.credit;
            //console.log(this.table1.data);
            
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
      },
      showAllList(){
        axios(config.api('get', `/report/SumAllList`)).then((response)=>{  
              //console.log(response.data.ResultData);
              this.table1.data = response.data.ResultData;
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
      },
      created(){
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const incurrentDate = `${year}-${month}-${day}`;
        this.currentDate = {
            start: incurrentDate,
            end: incurrentDate          
        };
          //console.log(this.currentDate);
      }
    },
    mounted() {
      // if(utils.permission('LOGS_VIEW')){
      //   axios(config.api('get', '/report/logsAdmin/1')).then((tableReq)=>{
      //    let dataArray = tableReq.data.ResultData
      //     this.taskList = dataArray
      //   },(error)=>{
      //     this.$alert(error.response.data.Message,'Error','error');
      //   });
      // }
      if(utils.permission('DASHBOARD_VIEW')){
        ////// Report SUM
        let startDate =  utils.forMatDate(Math.floor(Date.now()));
        // let endDate =  utils.forMatDate((Math.floor(Date.now() / 1000)+((60*60)*24))*1000);
        let endDate =  utils.forMatDate(Math.floor(Date.now()));
        this.forms.start = `${startDate}`;
        this.forms.end = `${endDate}`;
        
        this.created();
        this.clearList();
        // this.showAllList();
        // axios(config.api('post', '/report/findReportSunAll/',{start:`${startDate}`,end:`${endDate}`})).then((tableReq)=>{
        //   this.Agentcredit = tableReq.data.ResultData.AgentCredit.credit;
        //   let respData = tableReq.data.ResultData;
        //   this.table.expoet = respData.exportdata;
        //   for (var i = 0; i < this.table.data.length; i++) {
        //     this.table.data[i].sum = utils.forMatNymber(respData[this.table.data[i].key]);
        //   } 
        // },(error)=>{
        //   this.$alert(error.response.data.Message,'Error','error');
        // });
        ////// bigLineChart
        axios(config.api('get', '/report/profit/year')).then((tableReq)=>{
         this.bigLineChart = {
             tolat: {
               profit:utils.forMatMony(tableReq.data.ResultData.Profit.tolat),
               deposit:utils.forMatMony(tableReq.data.ResultData.Deposit.tolat),
               withdraw:utils.forMatMony(tableReq.data.ResultData.Withdraw.tolat)
             },
             chartData: {
               labels: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
               datasets: [{
                 label: "กำไร",
                 fill: true,
                 borderColor: config.colors.primary,
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: config.colors.primary,
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: config.colors.danger,
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Profit.allData,
               },{
                 label: "ถอน",
                 fill: true,
                 borderColor: config.colors.danger,
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: config.colors.danger,
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: config.colors.danger,
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Withdraw.allData,
               },{
                 label: "ฝาก",
                 fill: true,
                 borderColor: '#1d8cf8',
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: '#1d8cf8',
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: '#1d8cf8',
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Deposit.allData,
               }]
             }
           }
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
        ////// monthLineChartLineChart
        axios(config.api('get', '/report/profit/month')).then((tableReq)=>{
          ///console.log('tableReq.data.ResultData',tableReq.data.ResultData);
         this.monthLineChartLineChart = {
             tolat: {
               profit:utils.forMatMony(tableReq.data.ResultData.Profit.tolat),
               deposit:utils.forMatMony(tableReq.data.ResultData.Deposit.tolat),
               withdraw:utils.forMatMony(tableReq.data.ResultData.Withdraw.tolat)
             },
             month: utils.nameMonth(),
             chartData: {
               datasets: [{
                 label: "กำไร",
                 fill: true,
                 borderColor: config.colors.primary,
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: config.colors.primary,
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: config.colors.danger,
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Profit.allData,
               },{
                 label: "ถอน",
                 fill: true,
                 borderColor: config.colors.danger,
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: config.colors.danger,
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: config.colors.danger,
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Withdraw.allData,
               },{
                 label: "ฝาก",
                 fill: true,
                 borderColor: '#1d8cf8',
                 borderWidth: 2,
                 borderDash: [],
                 borderDashOffset: 0.0,
                 pointBackgroundColor: '#1d8cf8',
                 pointBorderColor: 'rgba(255,255,255,0)',
                 pointHoverBackgroundColor: '#1d8cf8',
                 pointBorderWidth: 20,
                 pointHoverRadius: 4,
                 pointHoverBorderWidth: 15,
                 pointRadius: 4,
                 data: tableReq.data.ResultData.Deposit.allData,
               }]
             }
           }
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
        ////// purpleLineChart
        axios(config.api('get', '/report/newMember/week')).then((tableReq)=>{
         this.purpleLineChart = {
           chartData: {
             labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
             datasets: [{
               label: "สรุปยอด",
               fill: true,
               borderColor: config.colors.primary,
               borderWidth: 2,
               borderDash: [],
               borderDashOffset: 0.0,
               pointBackgroundColor: config.colors.primary,
               pointBorderColor: 'rgba(255,255,255,0)',
               pointHoverBackgroundColor: config.colors.primary,
               pointBorderWidth: 20,
               pointHoverRadius: 4,
               pointHoverBorderWidth: 15,
               pointRadius: 4,
               data: tableReq.data.ResultData.data,
             }]
           }
         }
         this.purpleLineChart.sumToday = tableReq.data.ResultData.sumToday
         this.purpleLineChart.dataAll = tableReq.data.ResultData.dataAll
         this.purpleLineChart.sumWeek = tableReq.data.ResultData.sumWeek
         //this.initpurpleLineChart();
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
        ////// blueBarChart
        axios(config.api('get', '/report/deposit/week')).then((tableReq)=>{
          this.blueBarChart = {
            chartData: {
              labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
              datasets: [{
                label: "สรุปยอด",
                fill: true,
                borderColor: config.colors.info,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: tableReq.data.ResultData.data,
              }]
            }
          }
          this.blueBarChart.sumToday = tableReq.data.ResultData.sumToday
          this.blueBarChart.dataAll = tableReq.data.ResultData.dataAll
          this.blueBarChart.sumWeek = tableReq.data.ResultData.sumWeek
        //  this.initpurpleLineChart();
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
        ////// greenLineChart
        axios(config.api('get', '/report/withdraw/week')).then((tableReq)=>{
          this.greenLineChart = {
            chartData: {
              labels: ['อาทิตย์','จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
              datasets: [{
                label: "สรุปยอด",
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: tableReq.data.ResultData.data,
              }]
            }
          }
          this.greenLineChart.sumToday = tableReq.data.ResultData.sumToday
          this.greenLineChart.dataAll = tableReq.data.ResultData.dataAll
          this.greenLineChart.sumWeek = tableReq.data.ResultData.sumWeek
        //  this.initpurpleLineChart();
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
      ////// update Date
      // let today = Math.floor(Date.now() / 1000);
      // this.forms.start = new Date(today);
      // this.forms.end = new Date(today);
      //////
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'en';
        this.$rtl.enableRTL();
      }
      //this.initBigChart(0);
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
  .input-datetime input{
    color: #111111;
    background: none;
    border: none;
  }
  .ChartMap{
    margin: 0px;
    padding: 0px;
  }
  .ChartMap li{
    display: inline-block;
    margin-right: 30px;
  }
  .ChartMap li span{
    display: inline-block;
    width: 10px;
    height: 10px;
  }
  .ChartMap li:nth-child(1) span {
    background: #42b883;
  }
  .ChartMap li:nth-child(2) span {
    background: #1f8cf8;
  }
  .ChartMap li:nth-child(3) span {
    background: #fd5e93;
  }

</style>
